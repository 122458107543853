@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --blue-overlay: #f5f7fe;
  --blue-ocean-light: rgba(17, 114, 153, 0.3);
  --blue-ocean-hover: rgba(17, 114, 153, 0.4);
  --blue-ocean: #117299;
  --paragraphs-color: #414141;
  --field-shadow: 0 -4px 24px #c7d0f2;
}

.step-form-box {
  font-family: 'Inter';
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;

  * {
    box-sizing: border-box;
  }

  &-progress {
    position: relative;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--blue-ocean-light);
    margin-bottom: 10px;

    &-bar {
      border-radius: 10px;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-color: var(--blue-ocean);
      transition: width 0.3s ease 0s;
    }
  }

  form {
    position: relative;
    background-color: var(--blue-overlay);
    border-radius: 10px;
  }

  .form-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.7);

    img {
      max-width: 10rem;
    }
  }

  input {
    width: 100%;
    cursor: initial !important;
    color: #333;

    &:focus {
      border-color: #3898ec;
      outline: 0;
    }

    &[type='radio'] {
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:checked ~ span {
        border-color: var(--blue-ocean);
        background-color: var(--blue-ocean);
        background-image: url('https://uploads-ssl.webflow.com/65c4d21c00b7d21cc2601ab3/65c52b06fc6612ca6f8ab872_check-radio.svg');
      }
    }

    &[type='checkbox'] {
      width: auto;
      margin: 0;
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:checked ~ span {
        border-color: var(--blue-ocean) !important;
        background-color: var(--blue-ocean) !important;
        background-image: url('https://uploads-ssl.webflow.com/65c4d21c00b7d21cc2601ab3/65c52b06fc6612ca6f8ab872_check-radio.svg');
      }
    }
  }

  select {
    width: 100%;
    color: #717171 !important;
    padding: 0 1.25rem;

    option {
      color: inherit;
    }
  }

  select option[disabled][selected] {
    display: none;
  }

  .radio {
    display: inline-flex;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #d8d8d8;
    border-radius: 50%;
    margin-right: 1.25rem;
    transition: all 0.3s ease 0s;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .checkbox {
    display: inline-flex;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-width: 2px;
    border-style: solid;
    border-color: #d8d8d8;
    border-radius: 4px;
    margin-right: 1.25rem;
    transition: all 0.3s ease 0s;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
  }

  .step {
    position: relative;
    padding: 2.5rem 3.5rem 2.5rem;

    &-body {
      padding-bottom: 2.5rem;
    }

    &-title {
      color: var(--paragraphs-color);
      text-align: center;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.35;
      margin-bottom: 40px;

      & + h4 {
        margin-top: -25px !important;
      }

      & + p {
        margin-top: -20px;
        font-size: 1.125rem;
        font-family: inherit;
        color: #798194;
        text-align: center;
      }
    }

    &-subtitle {
      font-size: 1.5rem;
      text-align: center;
      color: var(--paragraphs-color);
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 40px;
    }

    &-fields-line {
      display: flex;
      align-items: center;
      gap: 0 1.25rem;

      & > * {
        flex: 1;
      }

      &-field {
        position: relative;
        height: 80px;
        cursor: pointer;
        background-color: #fff;
        border: 1px solid #595959;
        border-radius: 0.5rem;
        align-items: center;
        margin-bottom: 0;
        padding-left: 1.5rem;
        display: flex;
        box-shadow: var(--field-shadow);
        color: #333;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.25;
      }
    }

    &-action {
      display: flex;
      align-items: center;
      gap: 0 1.25rem;
      margin-bottom: 10px;
      button {
        border: 0 none;
        flex: 1;
        grid-column-gap: 1rem;
        grid-row-gap: 1rem;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        text-transform: uppercase;
        cursor: pointer;
        background-color: var(--blue-ocean-light);
        justify-content: flex-end;
        align-items: center;
        padding: 1.5rem 2.5rem;
        font-family: Inter, sans-serif;
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1;
        text-decoration: none;
        transition: all 0.35s;
        display: flex;

        &:hover {
          background-color: var(--blue-ocean-hover);
        }

        &.valid {
          background-color: var(--blue-ocean);
        }

        &.next {
          &::after {
            content: '';
            display: inline-block;
            width: 10px;
            height: 16px;
            background-image: url('https://uploads-ssl.webflow.com/65c4d21c00b7d21cc2601ab3/65c52b061ad6c3d8be9d4b3f_angle-right.svg');
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
          }
        }

        &.back {
          justify-content: flex-start;
          background-color: var(--blue-ocean);

          &::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 16px;
            background-image: url('https://uploads-ssl.webflow.com/65c4d21c00b7d21cc2601ab3/65c52b061ad6c3d8be9d4b3f_angle-right.svg');
            background-size: 10px;
            background-repeat: no-repeat;
            background-position: center;
            transform: scale(-1);
          }
        }
      }
    }

    &-note {
      color: #ff3a3a;
      text-align: center;
      background-color: #fff3f2;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

.input-box {
  position: relative;
  display: inline-flex;
  cursor: pointer;

  .checkbox {
    margin: 0;
  }
}

a {
  text-decoration: none;
}

label {
  a {
    color: var(--blue-ocean);
  }
}

.submit-note {
  display: flex;
  align-items: flex-start;
  gap: 0 1.25rem;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--paragraphs-color);
  margin-top: 2.5rem;
}

.icon-box {
  width: 8rem;
  height: 8rem;
  border: 1px solid #117299;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
  box-shadow: 4px 0 24px #c7d0f2;
}

@media only screen and (max-width: 992px) {
  .step-form-box .step {
    padding: 2.5rem 15px 2.5rem;
  }
}

@media (max-height: 499px) and (max-width: 899px), (max-width: 767px) {
  .submit-note {
    font-size: 1rem;
  }

  .step-form-box .step-action button {
    padding: 1.5rem 1rem;
    font-size: 0.8rem;
    gap: 0.8rem;
  }
}

@media only screen and (max-width: 599px) {
  .step-form-box .step-fields-line {
    gap: 1.25rem 0;
    flex-direction: column;
  }

  .step-form-box .step-fields-line > * {
    width: 100%;
    flex: initial;
  }

  .step-form-box .step-action {
    gap: 0 1rem;
  }
}
